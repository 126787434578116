<template>
  <div class="page">
    <!-- 搜索框 -->
    <div class="searchBox">
      <div class="searchBox_left">
        <img src="./img/search.png" alt="" />
        <input
          v-on:input="search()"
          v-model="keyWord"
          type="text"
          placeholder="搜索"
          @keyup.enter="handerSearch()"
        />
      </div>
      <div class="searchBox_right" @click="closeSearch()">取消</div>
    </div>
    <!-- 搜索列表 -->
    <div class="searchList" v-if="searching == 1">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toDetail(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <img src="./img/search.png" alt="" />
          <div class="content">
            <span>{{ item.str_s }}</span>
            <span style="color: rgba(0, 0, 0, 0.85); font-weight: 600">{{
              keyWord
            }}</span>
            <span>{{ item.str_e }}</span>
          </div>
        </div>
      </v-list>
    </div>
    <div class="nosearch" v-if="searching == 0">
      <!-- 搜索历史 -->
      <div class="searchHistory" v-if="historyList.length > 0">
        <div class="searchHistory_top">
          <div class="title">搜索历史</div>
          <img src="./img/delete.png" @click="isDialog = true" alt="" />
        </div>
        <div class="historyList">
          <div
            class="item"
            @click="keyWordChecked(item)"
            v-for="(item, index) in historyList"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 热门搜索 -->
      <div class="hotSearch">
        <div class="hotSearch_top">
          <div class="title">热门搜索</div>
        </div>
        <div class="rankList">
          <div class="left">
            <div
              class="item"
              @click="keyWordChecked(item.searchWords)"
              v-for="(item, index) in rankList_l"
              :key="index"
            >
              <div class="rank">
                <span
                  v-if="index + 1 == 1 || index + 1 == 2 || index + 1 == 3"
                  style="color: #fff"
                  >{{ index + 1 }}</span
                >
                <span v-else>{{ index + 1 }}</span>
                <img v-if="index + 1 == 1" src="./img/no1.png" alt="" />
                <img v-if="index + 1 == 2" src="./img/no2.png" alt="" />
                <img v-if="index + 1 == 3" src="./img/no3.png" alt="" />
              </div>
              <div class="content">{{ item.searchWords }}</div>
            </div>
          </div>
          <div class="right">
            <div
              class="item"
              @click="keyWordChecked(item.searchWords)"
              v-for="(item, index) in rankList_r"
              :key="index"
            >
              <div class="rank">
                <span>{{ index + 6 }}</span>
              </div>
              <div class="content">{{ item.searchWords }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="dialogTitle">提示</p>
      <p class="dialogText">确认删除搜索历史吗？</p>
    </v-dialog>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  getTopicListUrl,
  getLifeNewsTypeUrl,
  deleteSearchHistoryUrl,
  getSearchHistoryUrl,
  getHotSearchUrl,
} from "./api.js";
export default {
  name: "lifeExpertSearch",
  data() {
    return {
      isDialog: false,
      searching: 0,
      keyWord: "",
      historyList: [],
      rankList_l: [],
      rankList_r: [],
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 7,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  mounted() {
    this.getHistoryList();
    this.getRankList();
  },
  methods: {
    confirm() {
      // deleteSearchHistoryUrl
      let params = {
        userId: this.userId,
      };
      this.$axios
        .post(`${deleteSearchHistoryUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.isDialog = false;
            this.getHistoryList();
          }
        });
    },
    toDetail(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "lifeExpertDetail",
        query: params,
      });
    },
    keyWordChecked(str) {
      this.keyWord = str;
      this.listData = [];
      this.requestData.curPage = 1;
      this.finished = false;
      this.onLoad();
    },
    closeSearch() {
      if (this.searching == 1) {
        this.searching = 0;
        this.keyWord = "";
        this.getHistoryList();
        this.getRankList();
      }
    },
    handerSearch() {
      console.log(123);
    },
    search() {
      if (this.keyWord.length == 0) {
        this.searching = 0;
        return;
      }
      this.listData = [];
      this.requestData.curPage = 1;
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      this.getSearchList();
    },
    getSearchList() {
      this.searching = 1;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let params = {
          topicTypes: localStorage.getItem("topicTypes"),
          topicTitle: this.keyWord,
          userId: this.userId,
          sortField: "HOT",
          houseId: this.roomId || this.communityId || undefined,
        };
        this.$axios
          .get(
            `${getTopicListUrl}`,
            { params: params },
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((res) => {
            if (res.code === 200) {
              if (res.data.pages <= res.data.current) {
                this.finished = true;
              } else {
                this.$refs.list._data.loading = false;
              }
              this.finishedText = "没有更多了";
              if (res.data.records.length === 0) {
                this.finishedText = "";
                return;
              }
              res.data.records.forEach((item) => {
                this.listData.push(item);
              });
              this.listData.forEach((item, index) => {
                item.str_e = item.topicTitle.slice(
                  item.topicTitle.indexOf(
                    this.keyWord[this.keyWord.length - 1]
                  ) + 1,
                  item.topicTitle.length
                );
                if (item.topicTitle.indexOf(this.keyWord[0]) != 0) {
                  item.str_s = item.topicTitle.slice(
                    0,
                    item.topicTitle.indexOf(this.keyWord[0])
                  );
                }
              });
            }
          });
      }, 500);
    },
    // 热门搜索
    getRankList() {
      let params = {
        searchSouce: "1",
      };
      this.$axios.get(`${getHotSearchUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.rankList_l = res.data.slice(0, 5);
          this.rankList_r = res.data.slice(5, res.data.length + 1);
        }
      });
    },
    // 历史搜索
    getHistoryList() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${getSearchHistoryUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.historyList = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  padding-top: 30px;
  box-sizing: border-box;
  .dialogTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    padding: 50px 0 26px 0;
  }
  .dialogText {
    text-align: center;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 40px;
  }
  .searchList {
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 96px;
      border-bottom: 2px solid rgba(157, 157, 157, 0.1);
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 26px;
      }
      .content {
        margin-left: 8px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .hotSearch {
    margin-top: 68px;
    width: 100%;
    padding-left: 54px;
    padding-right: 46px;
    box-sizing: border-box;
    .rankList {
      display: flex;
      .left,
      .right {
        flex: 1;
        .item {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 34px;
          .rank {
            width: 36px;
            height: 36px;
            background: #f7f8fa;
            border-radius: 8px;
            line-height: 36px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            position: relative;
            color: rgba(0, 0, 0, 0.25);
            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
              color: rgba(0, 0, 0, 0.25);
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 36px;
              height: 36px;
            }
          }
          .content {
            padding: 0 18px;
            box-sizing: border-box;
            flex: 1;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .hotSearch_top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 34px;
      .title {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .searchHistory {
    margin-top: 36px;
    width: 100%;
    padding-left: 54px;
    padding-right: 46px;
    box-sizing: border-box;
    .historyList {
      display: flex;
      flex-wrap: wrap;
      .item {
        background: #f7f8fa;
        border-radius: 8px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        padding: 10px 30px;
        box-sizing: border-box;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
    .searchHistory_top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 34px;
      .title {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.25);
      }
      img {
        width: 22px;
        height: 24.93px;
      }
    }
  }
  .searchBox {
    width: 100%;
    height: 66px;
    padding-left: 54px;
    padding-right: 46px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .searchBox_left {
      flex: 1;
      height: 66px;
      background: #f7f8fa;
      border-radius: 33px;
      display: flex;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      img {
        width: 22px;
        height: 24px;
        margin-right: 14px;
      }
      input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .searchBox_right {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.5);
      margin-left: 24px;
    }
  }
}
</style>
